// ----------------------------------------------------------------------

export const company = [
  /* 'Lueilwitz and Sons',
  'Gleichner, Mueller and Tromp',
  'Nikolaus - Leuschke',
  'Hegmann, Kreiger and Bayer',
  'Grimes Inc',
  'Durgan - Murazik',
  'Altenwerth, Medhurst and Roberts',
  'Raynor Group',
  'Doctor',
  'Padberg - Bailey',
  'Heidenreich, Stokes and Parker',
  'Pagac and Sons',
  'Doctor',
  'Dare - Treutel',
  'Fitness Trainer',
  'Nolan - Kunde',
  'Fitness trainer',
  'Dibbert Inc',
  'Goyette and Sons',
  'Doctor',
  'Doctor',
  'Bartell - Kovacek',
  'Schimmel - Raynor',
  'Tremblay LLC',
  'Hills - Mitchell',
  'Rogahn LLC',
  'Kuhn, Osinski and Morar',
  'Schmitt Inc',
  'Breitenberg - Rosenbaum',
  "O'Keefe, Schneider and Mraz",
  'Rohan, Langworth and Kling',
  'Morar and Sons',
  'Mraz LLC',
  'Rowe, Parisian and Kub',
  'Marquardt - Hane',
  'Medhurst Group',
  'Nikolaus - Lang',
  'Effertz, Mohr and Olson',
  'Anderson - Kris',
  'Runolfsson Group' */
  'Doctor',
  'Dietician',
  'Dietician',
  'Dietician',
  'Dietician',
  'Dietician',
  'Dietician',
  'Dietician',
  'Dietician',
  'Dietician',

  'Doctor',
  'Doctor',
  'Doctor',
  'Doctor',
'Fitness trainer',
'Fitness trainer',
'Fitness trainer',
'Fitness trainer',
'Fitness trainer',
'Fitness trainer',
'Fitness trainer',

  
  'Fitness trainer',
  'Fitness trainer',
  'Fitness trainer',
  'Fitness trainer',
  'Doctor',
  'Fitness trainer',
  'Doctor',
  'Doctor',
  'Fitness trainer',
  'Doctor',
  'Doctor',
  'Doctor',
  'Doctor',
  'Fitness trainer',
  'Fitness trainer',
  'Fitness trainer',
  'Fitness trainer',
  'Fitness trainer',
  'Fitness trainer',
  'Fitness trainer',


];
