// ----------------------------------------------------------------------

export const role = [
  /* 'ux designer',
  'full stack designer',
  'backend developer',
  'ux designer',
  'ux designer',
  'project manager',
  'leader',
  'backend developer',
  'project manager',
  'ui designer',
  'ui/ux designer',
  'ui/ux designer',
  'ui designer',
  'backend developer',
  'backend developer',
  'front end developer',
  'backend developer',
  'full stack designer',
  'full stack developer',
  'backend developer',
  'ux designer',
  'ui designer',
  'project manager',
  'ui/ux designer',
  'ui designer',
  'project manager',
  'full stack developer',
  'hr manager',
  'hr manager',
  'ui/ux designer',
  'project manager',
  'full stack designer',
  'ui designer',
  'leader',
  'front end developer',
  'ui/ux designer',
  'project manager',
  'ui/ux designer',
  'ui designer',
  'full stack designer', */
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  "test@gmail.com",
  
];
